import React from 'react';
import SlideShow from './Slideshow';
import Main from './Main.js';
// import Footer from './Footer.js';

const HomePage = () => {
  return (
    <>
     {/* <FloatingWhatsApp /> */}
      {/* <Header /> */}
     <SlideShow />
     <Main />
     {/* <Footer /> */}
    </>
  );
};

export default HomePage;