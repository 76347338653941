import './Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding , faEnvelope, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <>
      <body>
    <nav>
        <div class="container-fluid p-0">
            <div class="container-fluid container-lg p-0">
                <div class="textColor">
                    <div class="row justify-content-center align-items-center mx-auto">
                        <div class="col-12 col-lg-3 p-0">
                            {/* <div class="logo"><img src='ojaslogo.png' style={{heigth:"50px" , width:"220px" , maxWidth:"800px"}} alt="logo"/></div> */}
                            <div className="logo">
  <img src="ojaslogo.png" className="logo-img" alt="logo" />
</div>

                             <hr class="d-block d-lg-none" /> 
                        </div>
                        <div class="col-4 col-lg-3 sideLine">
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="me-3 p-2 py-3">
                                       
                                <FontAwesomeIcon icon={faBuilding} className="iconHeight" /></div>
                                <div class="d-none d-lg-block"><span class="fw-medium">Opening Hour</span> <br />
                                    Mon-Fri : 8:00 - 9:00</div>
                            </div>
                        </div>
                        <div class="col-4 col-lg-3 sideLine">
                            <div class="d-flex justify-content-center align-items-center">
                                <a href="www.google.com">
                                    <div class="me-3 p-2 py-3"><FontAwesomeIcon icon={faPhoneVolume} className="iconHeight"/></div>
                                </a>
                                <div class="d-none d-lg-block"><span class="fw-medium">Call Us</span><br /> +1-800-123-1234</div>
                            </div>
                        </div>
                        <div class="col-4 col-lg-3">
                            <div class="d-flex justify-content-center align-items-center">
                                <a href="www.google.com">
                                    <div class="me-3 p-2 py-3"> <FontAwesomeIcon icon={faEnvelope} className="iconHeight"/></div>
                                </a>
                                <div class="d-none d-lg-block"><span class="fw-medium">Email Us</span><br /> example@emmail.com</div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-0 d-lg-none" />
                <div class="text-center display-3 fw-bold mb-2 d-lg-none"><img src='ojaslogo.png' style={{heigth:"50px" , width:"220px" , marginTop:'5px'}} alt="logo"/></div>
                <div class="navbarBgDark" data-bs-theme="dark">
                    <nav class="navbar navbar-expand-lg justify-content-center justify-content-lg-between p-0">

                        <button class="navbar-toggler m-3 w-100" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                            Menu
                        </button>
                        <div class="collapse navbar-collapse justify-content-between" id="navbarNavDropdown">
                            <ul class="navbar-nav text-uppercase ps-3">
                                <li class="nav-item">
                                    <Link to="/" className="nav-link active pe-3" aria-current="page" href="www.google.com">Home</Link>
                                </li>
                                <li class="nav-item">
                                <Link to="/" className="nav-link pe-3" aria-current="page" href="www.google.com">About Us</Link>
                                </li>
                                <li class="nav-item">
                                <a className="nav-link dropdown-toggle pe-3" href="www.google.com" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Products+
                    </a>
                    <ul className="dropdown-menu">
                        <li><Link to="Lubricants" className="dropdown-item" href="www.google.com">Lubricants</Link></li>
                        <li><Link to="IndustrialOils" className="dropdown-item" href="www.google.com">Industrial Oils</Link></li>
                        <li><Link to="AutomotiveOils" className="dropdown-item" href="www.google.com">Automotive Oils</Link></li>
                        <li><Link to="Greases" className="dropdown-item" href="www.google.com">Greases</Link></li>
                        <li><Link to="GearOils" className="dropdown-item" href="www.google.com">Gear Oils</Link></li>
                        <li><Link to="OtherProducts" className="dropdown-item" href="www.google.com">Other Products</Link></li>
                    </ul>
                                </li>
                                <li class="nav-item">
                                <Link to='/Location' className="nav-link pe-3" aria-current="page" href="www.google.com">Contact Us</Link>
                                </li>
                            </ul>
                            <div class="text-white m-1 p-3">
                                <button type="button" class="btn getBtn border border-white rounded-0">Product List</button>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </nav>
    


    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js"></script>
</body>

</>
  );
}

export default Header;


