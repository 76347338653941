import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEnvelope, faPhone, faPrint } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <div className="my-5">
      <footer className="text-center text-lg-start text-white" style={{ backgroundColor: '#45526e' }}>
        <div className="container p-4 pb-0">
          <section>
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">OJAS Petrochem</h6>
                <p>
                  Here you can use rows and columns to organize your footer content. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                </p>
              </div>
              <hr className="w-100 clearfix d-md-none" />
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">Products</h6>
                <p>
                  <a className="text-white" href="www.google.com">MDBootstrap</a>
                </p>
                <p>
                  <a className="text-white" href="www.google.com">MDWordPress</a>
                </p>
                <p>
                  <a className="text-white" href="www.google.com">BrandFlow</a>
                </p>
                <p>
                  <a className="text-white" href="www.google.com">Bootstrap Angular</a>
                </p>
              </div>
              <hr className="w-100 clearfix d-md-none" />
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">Useful links</h6>
                <p>
                  <a className="text-white" href="www.google.com">Your Account</a>
                </p>
                <p>
                  <a className="text-white" href="www.google.com">Become an Affiliate</a>
                </p>
                <p>
                  <a className="text-white" href="www.google.com">Shipping Rates</a>
                </p>
                <p>
                  <a className="text-white" href="www.google.com">Help</a>
                </p>
              </div>
              <hr className="w-100 clearfix d-md-none" />
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
                <p><FontAwesomeIcon icon={faHome} className="mr-3" /> New York, NY 10012, US</p>
                <p><FontAwesomeIcon icon={faEnvelope} className="mr-3" /> info@gmail.com</p>
                <p><FontAwesomeIcon icon={faPhone} className="mr-3" /> + 01 234 567 88</p>
                <p><FontAwesomeIcon icon={faPrint} className="mr-3" /> + 01 234 567 89</p>
              </div>
            </div>
          </section>
          <hr className="my-3" />
          <section className="p-3 pt-0">
            <div className="row d-flex align-items-center">
              <div className="col-md-7 col-lg-8 text-center text-md-start">
                <div className="p-3">
                © 2024 OJAS Petrochem. All rights reserved.
                </div>
              </div>
              <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                <a className="btn btn-outline-light btn-floating m-1 text-white" role="button" href="www.google.com"><i className="fab fa-facebook-f"></i></a>
                <a className="btn btn-outline-light btn-floating m-1 text-white" role="button" href="www.google.com"><i className="fab fa-twitter"></i></a>
                <a className="btn btn-outline-light btn-floating m-1 text-white" role="button" href="www.google.com"><i className="fab fa-google"></i></a>
                <a className="btn btn-outline-light btn-floating m-1 text-white" role="button" href="www.google.com"><i className="fab fa-instagram"></i></a>
              </div>
            </div>
          </section>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

