import React from 'react';
import './Products.css';
import { useState } from 'react';
import ProductDetail from './ProductDetail';

const ProductCard = ({ imageUrl, heading, onClick }) => {
    return (
      <div className="col-6 col-md-3 col-lg-3 mb-4 box-with-shadow" style={{ padding: "10px" }} onClick={onClick}>
        <div className="card h-100">
          <img className="card-img-top" src={imageUrl} alt={heading} style={{ height: '250px', width: 'auto' }} />
          <div className="card-body">
            <h4 className="card-title">{heading}</h4>
            {/* Additional details like price can be added here */}
          </div>
        </div>
      </div>
    );
  }

  const LubricantsPage = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const products = [
      { imageUrl: 'PICTURES-OJAS/15W40/15W40.jpg',imageUrl2: 'PICTURES-OJAS/15W40/15W40back.jpg', heading: 'WAY LUBE'},
      { imageUrl: 'PICTURES-OJAS/15W40/15W40.jpg',imageUrl2: 'PICTURES-OJAS/15W40/15W40back.jpg', heading: 'HLP Oils'},
      { imageUrl: 'PICTURES-OJAS/15W40/15W40.jpg',imageUrl2: 'PICTURES-OJAS/15W40/15W40back.jpg', heading: 'Hydraulic Oil'},
      // Add more products as needed
    ];

    const handleProductClick = (product) => {
      setSelectedProduct(product);
    }
  
    const handleCloseModal = () => {
      setSelectedProduct(null);
    }
   
    return (
      <div>
          <div className='container'> 
        <img src='./Automotive.jpg' className='products-img' alt=''/>
        </div>
        {/* <div className='container' style={{ height: '200px', width: '100%', position: 'relative', backgroundImage: 'url(bg-head.png)', backgroundSize: 'cover', backgroundPosition: 'center' , marginTop:'0px'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
            <h1 className="future-head" style={{ color: "#031026" }}>Lubricants</h1>
            <div className="underline" style={{ color: "#031026" }}></div>
          </div>
        </div> */}
  
        <div className='container'>
          <div className="row">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                imageUrl={product.imageUrl}
                heading={product.heading}
                onClick={() => handleProductClick(product)}
              />
            ))}
          </div>
        </div>
  
        {selectedProduct && (
          <ProductDetail
            imageUrl={selectedProduct.imageUrl}
            imageUrl2={selectedProduct.imageUrl2}
            heading={selectedProduct.heading}
            onClose={handleCloseModal}
          />
        )}
  
        <div className='container'>
          <h1 className="future-head" style={{ color: "Black" }}>Why Lubricants are essential </h1>
          <p className='info'>
Automotive oils are essential for the smooth and efficient operation of internal combustion engines, serving a multitude of critical functions that ensure the longevity, performance, and reliability of vehicles across various conditions and driving scenarios.Firstly, automotive oils act as lubricants, forming a protective barrier between moving engine parts, such as pistons, crankshafts, and camshafts, reducing friction and wear. This friction reduction not only extends the lifespan of engine components but also enhances fuel efficiency by minimizing energy losses due to frictional resistance.</p>
        </div>
      </div>
    );
  }
  

const IndustrialOilsPage = () => {

    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const products = [
        { imageUrl: '15W40.jpg', heading: 'HEAT TRANSFER OIL'},
        { imageUrl: '15W40.jpg', heading: 'PUNCHING AND STAMPING OIL'},       
        { imageUrl: '15W40.jpg', heading: 'TURBINE OIL'},
        { imageUrl: '15W40.jpg', heading: 'CUTTING OIL'},
    ];

    const handleProductClick = (product) => {
      setSelectedProduct(product);
    }
  
    const handleCloseModal = () => {
      setSelectedProduct(null);
    }
  
    return (
      <div>
         <div className='container'> 
        <img src='./Automotive.jpg' className='products-img' alt=''/>
        </div>
        {/* <div className='container' style={{ height: '200px', width: '100%', position: 'relative', backgroundImage: 'url(bg-head.png)', backgroundSize: 'cover', backgroundPosition: 'center' , marginTop:'0px'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
            <h1 className="future-head" style={{ color: "#031026" }}>Lubricants</h1>
            <div className="underline" style={{ color: "#031026" }}></div>
          </div>
        </div> */}
  
        <div className='container'>
          <div className="row">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                imageUrl={product.imageUrl}
                heading={product.heading}
                onClick={() => handleProductClick(product)}
              />
            ))}
          </div>
        </div>
  
        {selectedProduct && (
          <ProductDetail
            imageUrl={selectedProduct.imageUrl}
            heading={selectedProduct.heading}
            onClose={handleCloseModal}
          />
        )}
  
        <div className='container'>
          <h1 className="future-head" style={{ color: "Black" }}>Why Lubricants are essential </h1>
          <p className='info'>
Automotive oils are essential for the smooth and efficient operation of internal combustion engines, serving a multitude of critical functions that ensure the longevity, performance, and reliability of vehicles across various conditions and driving scenarios.Firstly, automotive oils act as lubricants, forming a protective barrier between moving engine parts, such as pistons, crankshafts, and camshafts, reducing friction and wear. This friction reduction not only extends the lifespan of engine components but also enhances fuel efficiency by minimizing energy losses due to frictional resistance.</p>
        </div>
      </div>
    );
}

const AutomotiveOilsPage = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const products = [
      { imageUrl: 'PICTURES-OJAS/15W40/15W40.jpg', imageUrl2: 'PICTURES-OJAS/15W40/15W40back.jpg', heading: '15W40 ENGINE OIL', docxDownloadUrl: 'DATA-SHEET/20W40.pdf' },
      { imageUrl: 'PICTURES-OJAS/20W40/20W40.jpg', imageUrl2: 'PICTURES-OJAS/20W40/20W40back.jpg', heading: '20W40 ENGINE OIL',docxDownloadUrl: 'DATA-SHEET/20W40.pdf'  },
      { imageUrl: 'PICTURES-OJAS/20W50/20W50.jpg', imageUrl2: 'PICTURES-OJAS/20W50/20W50back.jpg', heading: 'CF4 20W50 ENGINE OIL' },
    ];
  
    const handleProductClick = (product) => {
      setSelectedProduct(product);
    }
  
    const handleCloseModal = () => {
      setSelectedProduct(null);
    }
  
    const handlePdfDownload = (pdfDownloadUrl) => {
        // Constructing the URL based on the file location
        const url = `${process.env.PUBLIC_URL}/${pdfDownloadUrl}`;
        console.log("PDF Download URL:", url);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = '20W40.pdf'; // Set the correct filename
        document.body.appendChild(link);
    
        link.addEventListener('error', (event) => {
            console.error('Error downloading file:', event);
            // Handle error here, such as displaying a message to the user
        });
    
        link.click();
        document.body.removeChild(link);
    };
    
  
    return (
      <div>
        <div className='container'> 
        <img src='./Automotive.jpg' className='products-img' alt=''/>
        </div>

        <div className='container'>
          <div className="row">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                imageUrl={product.imageUrl}
                heading={product.heading}
                onClick={() => handleProductClick(product)}
              />
            ))}
          </div>
        </div>
  
        {selectedProduct && (
        <ProductDetail
        imageUrl={selectedProduct.imageUrl}
        imageUrl2={selectedProduct.imageUrl}
        imageUrl3={selectedProduct.imageUrl2}
        heading={selectedProduct.heading}
        docxDownloadUrl={selectedProduct.docxDownloadUrl} // Use the correct prop name
        onDownload={() => handlePdfDownload(selectedProduct.docxDownloadUrl)}
        onClose={handleCloseModal}
      />
        )}
  
        <div className='container'>
          <h1 className="future-head" style={{ color: "Black" }}>Why Automotive Oils are essential </h1>
          <p className='info'>
Automotive oils are essential for the smooth and efficient operation of internal combustion engines, serving a multitude of critical functions that ensure the longevity, performance, and reliability of vehicles across various conditions and driving scenarios.Firstly, automotive oils act as lubricants, forming a protective barrier between moving engine parts, such as pistons, crankshafts, and camshafts, reducing friction and wear. This friction reduction not only extends the lifespan of engine components but also enhances fuel efficiency by minimizing energy losses due to frictional resistance.</p>
        
        </div>
      </div>
    );
  }

const GreasesPage = ({ imageUrl, heading, price }) => {
    
    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const products = [
        { imageUrl: '15W40.jpg', heading: 'MULTI-PURPOSE GREASE'},
        { imageUrl: '15W40.jpg', heading: 'HIGH TEMPERATURE GREASE'},
        { imageUrl: '15W40.jpg', heading: 'MOLYBDENUM DISULFIDE GREASE'},
        { imageUrl: '15W40.jpg', heading: 'MARINE GREASE'},
        { imageUrl: '15W40.jpg', heading: 'SILICONE GREASE'},
    ];

    const handleProductClick = (product) => {
      setSelectedProduct(product);
    }
  
    const handleCloseModal = () => {
      setSelectedProduct(null);
    }
  
    return (
      <div>
         <div className='container'> 
        <img src='./Automotive.jpg' className='products-img' alt=''/>
        </div>
        {/* <div className='container' style={{ height: '200px', width: '100%', position: 'relative', backgroundImage: 'url(bg-head.png)', backgroundSize: 'cover', backgroundPosition: 'center' , marginTop:'0px'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
            <h1 className="future-head" style={{ color: "#031026" }}>Greases</h1>
            <div className="underline" style={{ color: "#031026" }}></div>
          </div>
        </div> */}
  
        <div className='container'>
          <div className="row">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                imageUrl={product.imageUrl}
                heading={product.heading}
                onClick={() => handleProductClick(product)}
              />
            ))}
          </div>
        </div>
  
        {selectedProduct && (
          <ProductDetail
            imageUrl={selectedProduct.imageUrl}
            heading={selectedProduct.heading}
            onClose={handleCloseModal}
          />
        )}
  
        <div className='container'>
          <h1 className="future-head" style={{ color: "Black" }}>Why Lubricants are essential </h1>
          <p className='info'>
Automotive oils are essential for the smooth and efficient operation of internal combustion engines, serving a multitude of critical functions that ensure the longevity, performance, and reliability of vehicles across various conditions and driving scenarios.Firstly, automotive oils act as lubricants, forming a protective barrier between moving engine parts, such as pistons, crankshafts, and camshafts, reducing friction and wear. This friction reduction not only extends the lifespan of engine components but also enhances fuel efficiency by minimizing energy losses due to frictional resistance.</p>
        </div>
      </div>
    );
}

const GearOilsPage = ({ imageUrl, heading, price }) => {
    
    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const products = [
        { imageUrl: '15W40.jpg', heading: 'AUTOMOTIVE GEAR OIL'},
        { imageUrl: '15W40.jpg', heading: 'INDUSTRIAL GEAR OIL'},
        { imageUrl: '15W40.jpg', heading: 'MARINE GEAR OIL'},
        { imageUrl: '15W40.jpg', heading: 'HYPOID GEAR OIL'},
    ];

    const handleProductClick = (product) => {
      setSelectedProduct(product);
    }
  
    const handleCloseModal = () => {
      setSelectedProduct(null);
    }
  
    return (
      <div>
         <div className='container'> 
        <img src='./Automotive.jpg' className='products-img' alt=''/>
        </div>
        {/* <div className='container' style={{ height: '200px', width: '100%', position: 'relative', backgroundImage: 'url(bg-head.png)', backgroundSize: 'cover', backgroundPosition: 'center' , marginTop:'0px'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
            <h1 className="future-head" style={{ color: "#031026" }}>Gear Oils</h1>
            <div className="underline" style={{ color: "#031026" }}></div>
          </div>
        </div> */}
  
        <div className='container'>
          <div className="row">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                imageUrl={product.imageUrl}
                heading={product.heading}
                onClick={() => handleProductClick(product)}
              />
            ))}
          </div>
        </div>
  
        {selectedProduct && (
          <ProductDetail
            imageUrl={selectedProduct.imageUrl}
            heading={selectedProduct.heading}
            onClose={handleCloseModal}
          />
        )}
  
        <div className='container'>
          <h1 className="future-head" style={{ color: "Black" }}>Why Lubricants are essential </h1>
          <p className='info'>
Automotive oils are essential for the smooth and efficient operation of internal combustion engines, serving a multitude of critical functions that ensure the longevity, performance, and reliability of vehicles across various conditions and driving scenarios.Firstly, automotive oils act as lubricants, forming a protective barrier between moving engine parts, such as pistons, crankshafts, and camshafts, reducing friction and wear. This friction reduction not only extends the lifespan of engine components but also enhances fuel efficiency by minimizing energy losses due to frictional resistance.</p>
        </div>
      </div>
    );
}

const OtherProductsPage = ({ imageUrl, heading, price }) => {
    
    const [selectedProduct, setSelectedProduct] = useState(null);
  
    const products = [
        { imageUrl: '15W40.jpg', heading: 'RUST PREVENTIVES'},
        { imageUrl: '15W40.jpg', heading: 'SOLUBLE CUTTING OIL'},
        { imageUrl: '15W40.jpg', heading: 'ANTI-FREEZE/COOLANT'},
        { imageUrl: '15W40.jpg', heading: 'ADDITIVES'},
    ];

    const handleProductClick = (product) => {
      setSelectedProduct(product);
    }
  
    const handleCloseModal = () => {
      setSelectedProduct(null);
    }
  
    return (
      <div>
         <div className='container'> 
        <img src='./Automotive.jpg' className='products-img' alt=''/>
        </div>
        {/* <div className='container' style={{ height: '200px', width: '100%', position: 'relative', backgroundImage: 'url(bg-head.png)', backgroundSize: 'cover', backgroundPosition: 'center' , marginTop:'0px'}}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
            <h1 className="future-head" style={{ color: "#031026" }}>Other Products</h1>
            <div className="underline" style={{ color: "#031026" }}></div>
          </div>
        </div> */}
  
        <div className='container'>
          <div className="row">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                imageUrl={product.imageUrl}
                heading={product.heading}
                onClick={() => handleProductClick(product)}
              />
            ))}
          </div>
        </div>
  
        {selectedProduct && (
          <ProductDetail
            imageUrl={selectedProduct.imageUrl}
            heading={selectedProduct.heading}
            onClose={handleCloseModal}
          />
        )}
  
        <div className='container'>
          <h1 className="future-head" style={{ color: "Black" }}>Why Lubricants are essential </h1>
          <p className='info'>
Automotive oils are essential for the smooth and efficient operation of internal combustion engines, serving a multitude of critical functions that ensure the longevity, performance, and reliability of vehicles across various conditions and driving scenarios.Firstly, automotive oils act as lubricants, forming a protective barrier between moving engine parts, such as pistons, crankshafts, and camshafts, reducing friction and wear. This friction reduction not only extends the lifespan of engine components but also enhances fuel efficiency by minimizing energy losses due to frictional resistance.</p>
        </div>
      </div>
    );
}

export { LubricantsPage, IndustrialOilsPage, AutomotiveOilsPage, GreasesPage , GearOilsPage,OtherProductsPage };