import './ProductDetail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';

const ProductDetail = ({ imageUrl, imageUrl2 , imageUrl3, heading, onClose, docxDownloadUrl, onDownload }) => {

    const handleDownload = () => {
        onDownload();
    };

    const [mainImage, setMainImage] = useState(imageUrl);
  const [thumbnailImage, setThumbnailImage] = useState(imageUrl2);
  const [thumbnailImage2, setThumbnailImage2] = useState(imageUrl3);

  const handleMainImageClick = () => {
    setMainImage(imageUrl3);
    setThumbnailImage(imageUrl);
  };

  const handleThumbnailImageClick = () => {
    setMainImage(imageUrl);
    setThumbnailImage2(imageUrl3);
  };

    return (
       <div className="product-detail-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
        <div className="product-detail" style={{ width: '80%', height: '90%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#FDF8F6', borderRadius: '8px', padding: '20px', display: 'flex' }}>
        <div style={{ overflowY: 'auto', overflowX: 'hidden',scrollbarWidth: 'none', flex: 1, '@media (min-width: 768px)': { overflowY: 'hidden' } }}>
        <section className="py-5">
            <div className="container" style={{padding:'0px' , margin:'0px'}}>
                <div className="row gx-5">
                    <aside className="col-lg-6">
                    <div className="border rounded-4 mb-3 d-flex justify-content-center">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          data-fslightbox="gallery1"
          className="rounded-4"
          target="_blank"
          data-type="image"
        >
          <img
            style={{ maxWidth: '100%', maxHeight: '100vh', margin: 'auto' }}
            className="rounded-4 fit"
            src={mainImage}
            alt="Gallery"
          />
        </a>
      </div>
      <div className="d-flex justify-content-center mb-3">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          data-fslightbox="gallery1"
          className="border mx-1 rounded-2"
          target="_blank"
          data-type="image"
        >
          <img
            width="60"
            height="60"
            className="rounded-2"
            src={thumbnailImage}
            alt="I2"
            onClick={handleThumbnailImageClick}
          />
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          data-fslightbox="gallery1"
          className="border mx-1 rounded-2"
          target="_blank"
          data-type="image"
        >
          <img
            width="60"
            height="60"
            className="rounded-2"
            src={thumbnailImage2}
            alt="I3"
            onClick={handleMainImageClick}
          />
        </a>
      </div>

                    </aside>
    <main className="col-lg-6">
            <div className="ps-lg-3">
            <button className="close-btn" onClick={onClose} style={{color:'#D9A04C', position: 'absolute', top: '10px', right: '10px', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '1.5rem' }}>X</button>
                <h4 className="title text-dark">
                    {heading}<br />
                   
                </h4>
                <div className="d-flex flex-row my-3">
                    <div className="text-warning mb-1 me-2">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStarHalfAlt} />
                        <span className="ms-1">4.5</span>
                    </div>
                    <span className="text-success ms-2">In stock</span>
                </div>

                <p style={{textAlign:"left"}}>
                    

Engine oil is a lubricant vital for engine function, reducing friction, cooling, sealing, and cleaning internal components. It maintains engine health, ensuring smooth operation and longevity. Regular oil changes are essential to maintain optimal performance and prevent engine damage.
                </p>

                <div className="row" >
                    <dt className="col-3">TYPE</dt>
                    <dd className="col-9">Engine Oil</dd>
                </div>

                <div className="row">
                    <dt className="col-3">SIZE</dt>
                    <dd className="col-9">20 / 26 / 180 Liters</dd>
                </div>

                <hr />
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={handleDownload} className="btn btn-warning shadow-0"> Download Data Sheet </a>
            </div>
        </main>
        </div>
        </div>
        </section>
        </div>
        </div>
        </div>
    );
  }

  export default ProductDetail ;