import React from 'react';
import Header from './Header.js';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Footer from './Footer.js';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from './HomePage.js'
import { LubricantsPage, IndustrialOilsPage, AutomotiveOilsPage, GreasesPage , GearOilsPage,OtherProductsPage } from './Products';
import LocationPage from './Location.js';

const App = () => {
  return (
    <>
      <Router>
      <>
        <FloatingWhatsApp
          phoneNumber="8076255880" // Replace with your WhatsApp phone number
          accountName="Sonal" // Replace with your WhatsApp account name
        />
        <Header />
        {/* <Product /> */}
        <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Lubricants" element={<LubricantsPage />} />
        <Route path="/IndustrialOils" element={<IndustrialOilsPage />} />
        <Route path="/AutomotiveOils" element={<AutomotiveOilsPage/>} />
        <Route path="/Greases" element={<GreasesPage />} />
        <Route path="/GearOils" element={<GearOilsPage />} />
        <Route path="/OtherProducts" element={<OtherProductsPage />} />
        <Route path="/Location" element={<LocationPage />} />
        </Routes>
         <Footer />
      </>
    </Router>
    </>
  );
};

export default App;
